.container.contact__container {
    width: 58%;
    display: grid;
    /*grid-template-columns: 30% 55%;
    gap: 15%;*/
}

.container.contact__container .btns {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
}

.container.contact__container .message {
    color: var(--color-primary);
    font-size: 0.9rem;
}

.contact__options {
    display: grid;
    gap: 1rem;
}

.contact__options a {
    color: var(--color-white);
    display: inline-block;
    font-size: 1rem;
}

.contact__options a:hover {
    color: var(--color-light);
}

.contact__option {
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1.2rem;
    padding: 1.2rem;
    text-align: center;
    transition: var(--transition);
}

.contact__option:hover {
    border-color: var(--color_primary-variant);
}

.contact__option-icon {
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

.contact__option h3 {
    font-size: 1.4rem;
}

.contact__option p {
    font-size: 1.2rem;
}

form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

input,textarea {
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-bg);
    resize: none;
    color: var(--color-white);
}
input::placeholder,
textarea::placeholder {
    color: var(--color-light);
}

/*  ====================== MEDIA QUERY (TABLETS) ======================  */
@media  screen and (max-width: 1023px) {
    .container.contact__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
    .contact__option h3 {
        font-size: 1.2rem;
    }
} 

/*  ====================== MEDIA QUERY (MOBILE) ======================  */
@media  screen and (max-width: 767px) {
    .container.contact__container {
        width: var(--container-width-sm);
    }
}