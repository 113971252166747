@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300;500;600&display=swap');
* {
     margin: 0;
     padding: 0;
     border: 0;
     outline: 0;
     box-sizing: border-box;
     list-style: none;
     text-decoration: none;
}

:root {
     --color-bg: #1f1f38;
     --color-bg-variant: #2c2c6c; /* #3a3a57;*/
     --color-primary: #2f93db;
     --color-primary-variant: rgba(77,181,255,0.6);
     --color-white: #fff;
     --color-light: rgba(255,255,255,0.8);
     --color-light-blue: rgba(0,212,255,1);
     --color-gold: rgba(255, 215, 0, 1);
     --container-width-lg: 75%;
     --container-width-md: 80%;
     --container-width-sm: 90%;
     --transition: all 400ms ease;
}

html {
     scroll-behavior: smooth;
     line-height: 1.15; /* 1 */
     -webkit-text-size-adjust: 100%; /* 2 */
}

::-webkit-scrollbar {
     display: none;
}

body {
     font-family: 'Source Code Pro', monospace;
     background: rgb(0,212,255);
     background: linear-gradient(0deg, rgba(0,212,255,1) 0%, rgba(9,9,121,1) 100%);
     color: var(--color-white);
     line-height: 1.7;
}

a {
     color: var(--color-primary);
     transition: var(--transition);
}

a:hover {
     color: var(--color-white);
}

a.scroll__down {
     color: var(--color-white);
}

a.scroll__down:hover {
     color: var(--color-light);
}

h1,h2,h3,h4,h5,h6 {
     font-weight: 500;
}

h1 {
     font-size: 2.8rem;
}

h2 {
     font-size: 2.4rem;
}

h3 {
     font-size: 1.8rem;
}

h4 {
     font-size: 1.4rem;
}

h5 {
     font-size: 1.2rem;
}

img {
     display: block;
     width: 100%;
     height: auto;
     object-fit: cover;
}

section {
     margin-top: 4rem;
     /* height: 100vh; */
}

section > h2,
section > h5 {
     text-align: center;
}

section > h2 {
     margin-bottom: 3rem;
     color: var(--color-gold);
}

.container {
     width: var(--container-width-lg);
     margin: 0 auto;
}

.text-light {
     color: var(--color-light);
}

.btn {
     display: inline-block;
     color: var(--color-primary);
     font-size: 1rem;
     padding: 0.75rem 1.2rem;
     border-radius: 0.4rem;
     cursor: pointer;
     border: 1px solid var(--color-primary);
     transition: var(--transition);
}

.btn:hover {
     background: var(--color-white);
     color: var(--color-bg);
     border-color: transparent;
}

.btn-primary {
     background: var(--color-bg-variant);
     color: var(--color-white);
}

/*  ====================== MEDIA QUERY (TABLETS) ======================  */
@media  screen and (max-width: 1023px) {
     .container {
          width: var(--container-width-md);
     }

     section {
          margin-top: 2rem;
     }
} 

/*  ====================== MEDIA QUERY (MOBILE) ======================  */
@media  screen and (max-width: 767) {
     .container {
          width: var(--container-width-sm);
     }

     section > h2 {
          margin-bottom: 2rem;
     }
} 