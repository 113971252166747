.services__container {
    grid-gap: 2.5rem;
    border-bottom: 1px solid var(--color-light);
    display: grid;
    gap: 2.5rem;
    grid-template-columns: repeat(3,1fr);
    padding: 0 0 2rem;
}

.service {
    background: var(--color-bg-variant);
    border-radius: 0 0 2rem 2rem;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.service:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.service__head {
    background: var(--color-primary);
    padding: 1rem;
    border-radius: 0 0 2rem 2rem;
    box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}

.service__head h3 {
    text-align: center;
    color: var(--color-white);
    font-size: 1.4rem;
}

.service__list {
    display: grid;
    margin: 0 1rem;
}

.service__list li {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.8rem;
}

svg.service__list-icon {
    color: var(--color-primary);
    margin-top: 2px;
}

.service:hover svg.service__list-icon {
    color: var(--color-white);
}

.service__list p {
    font-size: 1rem;
}

/*  ====================== MEDIA QUERY (TABLETS) ======================  */
@media  screen and (max-width: 1023px) {
    .services__container {
        grid-template-columns: 1fr;
        gap: 2rem;
        /*width: 54% !important;*/
    }

    .service {
        height: auto;
    }
} 

/*  ====================== MEDIA QUERY (MOBILE) ======================  */
@media  screen and (max-width: 767px) {
    .services__container {
        gap: 1.5rem;
    }
    .service__list {
        padding: 2rem;
    }
}