.experience__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    border-bottom: 1px solid var(--color-light);
    padding: 0 0 2rem 0;
}

.experience__container > div {
    background: var(--color-bg-variant);
    padding: 2.4rem 1rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.experience__container > div:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.experience__container > div h3 {
    text-align: center;
    margin-bottom: 1.6rem;
    color: var(--color-white);
    font-size: 1.6rem;
}

.experience__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
}

.experience__details {
    display: flex;
    gap: 1rem;
}

.experience__details h4 {
    font-size: 1.6rem;
}

.experience__details small {
    color: var(--color-gold);
}

.experience__details-icon {
    margin-top: 6px;
    color: var(--color-primary);
    width: 2.5rem;
    height: 2.5rem;
}

/*  ====================== MEDIA QUERY (TABLETS) ======================  */
@media  screen and (max-width: 1023px) {
    .experience__details h4 {
        font-size: 1.4rem;
    }
    .experience__container {
        grid-template-columns: 1fr;
    }
} 

/*  ====================== MEDIA QUERY (MOBILE) ======================  */
@media  screen and (max-width: 767px) {
    .experience__details h4{
        font-size: 1.1rem;
    }
    .experience__container {
        gap: 1rem;
    }
    .experience__details-icon {
        width: 1rem;
        height: 1rem;
    }
}