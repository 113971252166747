.about__container {
    display: grid;
    /* grid-template-columns: 35% 50%;*/
    gap: 15%;
    border-bottom: 1px solid var(--color-light);
    padding: 0 0 4rem 0;
}

.about__me {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg, transparent, var(--color-primary-variant),transparent);
    display: grid;
    place-items: center;
}

.about__me-image {
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition);
}

.about__me-image:hover {
    transform: rotate(0);
}

.about__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.about__card {
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: var(--transition)
}

.about__card:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.about__icon {
    color: var(--color-primary);
    font-size: 2rem;
    margin-bottom: 1rem;
}

.about__card h5 {
    font-size: 2rem;
}

.about__card small {
    font-size: 1rem;
    color: var(--color-light);
}

.about__content p {
    margin: 2rem 0;
    color: var(--color-white);
    font-size: 1.2rem;
}
.about__content .mb20 {
    margin-bottom:20px;
}

/*  ====================== MEDIA QUERY (TABLETS) ======================  */
@media  screen and (max-width: 1023px) {
    .about__card {
        background: var(--color-white);
        padding: 1rem;
    }
    .about__icon {
        color: var(--color-bg-variant);
    }
    .about__card h5 {
        color: var(--color-bg-variant);
        font-size: 1.5rem;
    }
    .about__card small {
        color: var(--color-bg-variant);
    }
    .about__content p {
        color: var(--color-white);
        margin: 1rem 0 1.5rem;
    }
    .about__container {
        grid-template-columns: 1fr;
        gap: 0;
    }
    /*.about__me {
        width: 50%;
        margin: 2rem auto 4rem;
    }*/
} 

/*  ====================== MEDIA QUERY (MOBILE) ======================  */
@media  screen and (max-width: 767px) {
    /*.about__me {
        width: 65%;
        margin: 0 auto 3rem;
    }*/
    .about__cards {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    .about__content p {
        margin: 1.5rem 0;
    }
} 