header {
     padding-top: 7rem;
     overflow: hidden;
}

.header__container {
     display: grid;
     grid-template-columns: 1fr 1fr;
     gap: 2rem;
     border-bottom: 1px solid var(--color-light);
     padding: 0 0 2rem 0;
     text-align: center;
 }


.header__container {
     text-align: center;
     border-bottom: 1px solid var(--color-light);
}

.header__container h1 {
     color: var(--color-white);
}

.header__container h2 {
     color: var(--color-gold);
}

.header__container h1.header__name {
     color: var(--color-gold);
     font-size: 3.2rem;
}

.header__content p {
     margin: 2.5em 0 0;
     color: var(--color-primary);
     font-size: 2rem;
}

/* ============ CTA ============ */
.cta {
     margin-top: 2.5rem;
     display: flex;
     gap: 1.2rem;
     justify-content: center;
}

/* ============ HEADER SOCIALS ============ */
.header__socials {
     display: flex;
     flex-direction: column;
     align-items: center;
     gap: 0.8rem;
     position: fixed;
     left: 3rem;
     bottom: 3rem;
     font-size: 3rem;
}
.header__socials a {
     color: var(--color-white);
}
.header__socials a:hover {
     color: var(--color-light);
}

.cta .btn-download {
     background-color: var(--color-white);
}
.cta .btn-download:hover {
     background-color: var(--color-light);
}

/* ============ IMAGE  ============ */
.me {
     background: linear-gradient(var(--color-primary), transparent);
     margin: auto;
     border-radius: 50%;
     overflow: hidden;
     width: 80%;
}

/* ============ IMAGE  ============ */
.scroll__down {
     position: fixed;
     right: 3rem;
     bottom: 5rem;
     font-weight: 300;
     font-size: 3rem;
}

/*  ====================== MEDIA QUERY (TABLETS) ======================  */
@media  screen and (max-width: 1023px) {
     header {
          padding-top: 5rem;
     }
     .header__container {
          grid-template-columns: 1fr;
     }
     .header__container h1{
          font-size: 2.4rem;
     }
     .header__container h2{
          font-size: 2rem;
     }
     .header__socials, 
     .scroll__down {
          font-size:2rem;
     }
     .header__socials {
          left: 2rem;
     }
     .me {
          width: 75%
     }
     .scroll__down {
          right: 2rem;
     }

} 

/*  ====================== MEDIA QUERY (MOBILE) ======================  */
@media  screen and (max-width: 767px) {
     .container {
          width: var(--container-width-sm);
      }
     header {
          padding-top: 4rem;
     }
     br.phone {
          display: none;
     }
     .header__container h1{
          font-size: 2rem;
     }
     .header__container h2{
          font-size: 1.8rem;
     }
     .cta {
          display: grid;
     }
     .cta .btn {
          width: auto;
     }
     .header__socials,.scroll__down {
          display: none;
     }
     .me {
          margin: 2rem auto 0;
          width: 60%
     }
} 